import React from 'react';
import Waiter from "../controls/Waiter.mjs";
import "./Linkset.css";
import {UncontrolledTooltip} from "reactstrap";
import AutoIcon from "../controls/AutoIcon.mjs";

const G = window.glob;
const baseName='Linkset';

const handleUri = uri => {
	if ( typeof uri !== "string" ) return;
	if ( uri.startsWith("http") || uri.startsWith("/") ) return window.open(uri,'foo','toolbar=1');
	// eslint-disable-next-line
	if ( uri.startsWith("javascript:") ) return eval( uri.replace(/^javascript:/,''));
	window.location.href = uri;
}

const Linkset = class Linkset extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			lan: G.prop.isLAN,
			wan: ! G.prop.isLAN
		};
		if ( this.props.data?.links ) {
			this.state.list = this.props.data.links;
			this.state.label = this.props.data.label;
		}
		this.uid=G.generateUid(baseName);
		this.load = this.load.bind(this);
	}
	async load() {
		const all = await G.fetch('links');
		const lset = all[ this.props.set ];
		const links = (lset && lset?.links && lset?.links.length ? lset.links : [])
			.filter( l => ( (l.lan && this.state.lan) || ( l.wan && this.state.wan ) || ( ! l.lan && ! l.wan ) ) );
		if ( links.length ) {
			this.setState({list: links, label: lset.label });
		} else {
			this.setState({list: false});
		}
	}
	render() {
		if ( typeof this.state.list === 'undefined' ) {
			setTimeout( this.load );
			return(
				<div className={"text-center"}>
					<Waiter />
				</div>
			);
		}
		if ( ! this.state.list ) return '';
		return(
			<div className={baseName} id={this.uid}>
				{ this.state.list.map( ( link, index ) => (
					<div
						className={baseName+"Item"}
						id={this.uid+'-'+index}
						key={ index }
						onClick={ () => { handleUri(link.uri) } }
					>
						<div className={"icon"}>
							<AutoIcon value={ link.icon } />
						</div>
						<div className={"name"}>{link.name}</div>
						{ link.desc && (
							<UncontrolledTooltip
								target={this.uid+'-'+index}
								placement={"auto"}
							>
								{link.desc}
							</UncontrolledTooltip>
						)}
					</div>
				))}
			</div>
		)
	}
}

export default Linkset;