import React from 'react';

const TrashIcon = class extends React.Component {
	render() {
		return (
			<svg fill="currentColor" stroke="none" xmlns="http://www.w3.org/2000/svg" viewBox="2 2 30 30">
				<path d="M7,26a2.00006,2.00006,0,0,0,2,2H23a2.00006,2.00006,0,0,0,2-2V10H7ZM20,14a1,1,0,0,1,2,0V24a1,1,0,0,1-2,0Zm-5,0a1,1,0,0,1,2,0V24a1,1,0,0,1-2,0Zm-5,0a1,1,0,0,1,2,0V24a1,1,0,0,1-2,0ZM26,6V8H6V6A1,1,0,0,1,7,5h6V4a1,1,0,0,1,1-1h4a1,1,0,0,1,1,1V5h6A1,1,0,0,1,26,6Z"/>
			</svg>
		);
	}
}

export default TrashIcon;