import React from 'react';
import ReactDOM from 'react-dom/client';
import './libs/glob.js';
import './media/css/index.css';
import './media/css/MyBootstrap.css';
import App from './components/App.mjs';
import reportWebVitals from './reportWebVitals';

const G = window.glob;

const start = () => {
    if (G?.prop?.loadedComponents?.core) {
        const root = ReactDOM.createRoot(G.prop.appRootNode);
        root.render(
            <React.StrictMode>
                <App/>
            </React.StrictMode>
        );
    } else {
        setTimeout(start, 100);
    }
}

start();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
