import React from 'react';
import "./Home.css";
import Linkset from "./Linkset.mjs";
import Calendar from "./Calendar.mjs";
import TextLabel from "../controls/TextLabel.mjs";
import WeekDay from "./WeekDay.mjs";
import Meteo from "./Meteo.mjs";
import Traffic from "./Traffic.mjs";
import AutoIcon from "../controls/AutoIcon.mjs";
import LanguageSwitcher from "../controls/LanguageSwitcher.mjs";
import Annunci from "./Annunci.mjs";

const G = window.glob;

const Home = class Home extends React.Component {
	constructor(props) {
		super(props);
		this.state={
			lang: G.getLanguage(),
			langLong: G.getLanguageLong()
		};
		this.uid = G.generateUid('Home');
	}
	componentDidMount() {
		G.register("Prefs", l=>{
			if ( l?.language !== this.state.lang ) this.setState({
				lang: l?.language,
				langLong: G.getLanguageLong( l?.language )
			});
		}, this.uid);
	}
	componentWillUnmount() {
		G.unregister("Prefs", this.uid);
	}
	render() {
		const d = new Date();
		return(
			<div className="Home" id={this.uid}>
				<div>
					<Annunci carousel={true} />
					<h2 className={"date"}>
						{d.toLocaleDateString(this.state.langLong, {weekday: 'long', month: 'long', day: 'numeric'})}:
					</h2>
					<WeekDay/>
					{((new Date()).getHours() > 17) && (
						<>
							<h2 className={"mt-1 mb-1"}>
								<small><TextLabel value={"Tomorrow"}/>:</small>
							</h2>
							<WeekDay weekday={(d.getDay() + 1) % 7}/>
						</>
					)}
				</div>
				<Traffic/>
				<div>
					<h2>
						<AutoIcon value={"calendar"}/>
						<TextLabel value={"Next dates"}/>:
					</h2>
					<Calendar max={6}/>
				</div>
				<div>
					<h2>
						<AutoIcon value={"link"}/>
						<TextLabel value={"link-default"}/>:
					</h2>
					<Linkset set={"default"}/>
				</div>
				<div>
					<h2>
						<AutoIcon value={"meteo"}/>
						<TextLabel value={"Weather forecasts"}/>:
					</h2>
					<Meteo/>
				</div>
				<hr className={"w-75"}/>
				<div>
					<LanguageSwitcher/>
				</div>
			</div>
		)
	}
}

export default Home;