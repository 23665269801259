import React from "react";
import "./Vswitch.css";
import {Col, Container, Row} from "reactstrap";
import TextLabel from "./TextLabel.mjs";

const G = window.glob;

const VSwitch = class extends React.Component {
	constructor(props) {
		super(props);
		this.state = {items: []};
		if (props?.items && Array.isArray(props?.items) && props.items.length) {
			props.items.map(i => {
				if (typeof i === "string") {
					return {value: i, label: i};
				}
				if (typeof i === "object") {
					const no = {};
					Object.entries(i).forEach(([k, v]) => {
						no[k] = v;
					});
					no.value ??= i.label;
					no.label ??= i.value;
					return no;
				}
				return false;
			}).filter(i => (!!i)).forEach((i, idx) => {
				i.index = idx;
				this.state.items.push(i);
			});
			if (this.state.items.length) {
				this.state.items[0].first = true;
				this.state.items[this.state.items.length - 1].last = true;
			}
		}
		if (typeof props?.current !== 'undefined') {
			this.state.current = props.current;
		} else if (this.state.items.length) {
			this.state.current = this.state.items[0].value;
		}
		this.uid = G.generateUid("VSwitch");
		this.renderLine = this.renderLine.bind(this);
		this.change = this.change.bind(this);
	}

	change(idx) {
		const item = this.state.items[idx];
		if (item.value === this.state.current) return;
		this.setState({current: item.value});
		if (typeof this.props.onChange === "function") {
			this.props.onChange({
				label: item.label,
				value: item.value
			});
		}
	}

	renderLine(l) {
		const cl = [];
		const active = l.value === this.state.current;
		cl.push(active ? 'active' : 'inactive');
		if (l.first) cl.push('first');
		if (l.last) cl.push('last');
		if (l.className) cl.push(l.className)
		return (
			<Row
				key={l.index}
				className={cl.join(' ')}
				role={"button"}
				aria-pressed={active}
				onClick={() => {
					if (!active) this.change(l.index)
				}}
			>
				<Col className={"switchLane"}>
					<span/>
				</Col>
				<Col className={"switchLabel"}>
					{l.localize ? <TextLabel value={l.label}/> : l.label}
				</Col>
			</Row>
		);
	}

	render() {
		return (
			<div className={"VSwitch"} id={this.uid}>
				{this.props.title ? <h4>{this.props.title}</h4> : ""}
				<Container fluid={true}>
					{this.state.items.map(this.renderLine)}
				</Container>
			</div>
		);
	}
}

export default VSwitch;
