import React from 'react';

const CarIcon = class extends React.Component {
	render() {
		return (
			<svg viewBox="3 4 26 26" xmlns="http://www.w3.org/2000/svg" fill="currentColor" stroke="none" style={this.props.style||{}}>
				<path d="M27.16541,14.38342,25.67456,7.9231A3.96238,3.96238,0,0,0,22,5H10A3.96238,3.96238,0,0,0,6.32544,7.9231L4.83459,14.38342A1.99637,1.99637,0,0,0,4,16V27a1,1,0,0,0,1,1H7a1,1,0,0,0,1-1V24H24v3a1,1,0,0,0,1,1h2a1,1,0,0,0,1-1V16A1.99637,1.99637,0,0,0,27.16541,14.38342ZM8.27393,8.373A1.96715,1.96715,0,0,1,10,7H22a1.96715,1.96715,0,0,1,1.72607,1.373L24.79358,13H7.20642ZM10,17.5a.5.5,0,0,1-.5.5h-3a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h3a.5.5,0,0,1,.5.5Zm16,0a.5.5,0,0,1-.5.5h-3a.5.5,0,0,1-.5-.5v-1a.5.5,0,0,1,.5-.5h3a.5.5,0,0,1,.5.5Z"/>
			</svg>
		);
	}
}

export default CarIcon;