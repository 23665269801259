if (!window.glob?.prop?.loadedComponents?.core) {

	if (typeof window.glob !== "object") window.glob = {};
	const glob = window.glob;
	if (typeof glob.prop !== "object") glob.prop = {};
	if (typeof glob.prop.registered !== "object") glob.prop.registered = {};
	if (typeof glob.prop.status !== "object") glob.prop.status = {};
	if (typeof glob.prop.data !== "object") glob.prop.data = {};
	if (typeof glob.prop.loadedComponents !== "object") glob.prop.loadedComponents = {};

	// -----------------------------------------------------------------------------------------------------------------
	glob.prop.isDeploy = window.location.port ? (parseInt(window.location.port) < 1024) : true;
	glob.prop.keyApplicationName = 'Home-Oli';
	glob.prop.uri = {server: ""};
	glob.prop.uri.root = glob.prop.isDeploy ? '/' : 'https://ollivud.ovh/';
	glob.prop.isLAN = ! glob.prop.isDeploy || window.location.hostname.includes('crossbow.it');
	glob.prop.uri.api = glob.prop.uri.root+'ds/db/';
	glob.prop.uri.data = glob.prop.uri.root+'data/';
	glob.prop.uri.localpath = window.location.pathname;
	glob.prop.uri.web = glob.prop.uri.server + glob.prop.uri.localpath;
	glob.prop.appRootNode = document.getElementById("appRootNode");
	glob.prop.homePage = {name: "Home"};
	// -----------------------------------------------------------------------------------------------------------------

	glob.generateUid = ( prefix = "unk") => {
		return prefix + "-" + ("10000000-1048-4000-8000-1400800110084").replace(/[018]/g, c =>
			(c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
		);
	};

	glob.clone = (x) => {
		if (typeof x === "undefined") return undefined;
		try {
			return window.structuredClone ? window.structuredClone(x) : JSON.parse(JSON.stringify(x));
		} catch (e) {}
		return x;
	};

	glob.getAppWebRoot = () => { return glob.prop.uri.localpath; }

	glob.register = (contexts, trigger, uuid) => {
		if (typeof contexts === 'string') contexts = [contexts];
		if (typeof trigger !== 'function') {
			glob.unregister(contexts, uuid);
			return;
		}
		const uid = (typeof uuid === 'string') ? uuid : glob.generateUid('Status');
		if (Array.isArray(contexts)) contexts.forEach(c => {
			try {
				if (typeof glob.prop.registered[c] === 'undefined') glob.prop.registered[c] = {};
				glob.prop.registered[c][uid] = trigger;
			} catch (e) {
				console.error("glob.register", e, contexts, uid, trigger);
			}
		});
		if ( uuid && contexts.length === 1) return glob.getStatus(contexts[0]);
		return () => {
			glob.unregister(contexts, uid);
		};
	};
	glob.unregister = (contexts, uid) => {
		if (typeof uid !== 'string') return;
		if (typeof contexts === 'string') contexts = [contexts];
		if (Array.isArray(contexts)) contexts.forEach(c => {
			try {
				if (typeof glob.prop.registered[c] === 'object') delete glob.prop.registered[c][uid];
			} catch (e) {
				console.error("glob.unregister", e, uid, contexts);
			}
		});
	};
	glob.deregister = ( uid ) => {
		if (typeof uid !== 'string') return;
		Object.keys( glob.prop.registered || {} ).forEach( k => {
			delete glob.prop.registered[k][uid];
		});
	} ;
	glob.trigger = async (context, ...args) => {
		if (typeof context !== 'string') return;
		if (glob.prop.registered[context]) Object.entries(glob.prop.registered[context]).forEach(([uid, trigger]) => {
			if (typeof trigger === 'function') setTimeout(() => {
				trigger.apply(undefined, glob.clone(args));
			}, 10);
		});
	};
	glob.getStatus = (s) => {
		let v = glob.prop.status[s];
		return glob.clone(v);
	};
	glob.setStatus = (s, v, store) => {
		if ( store || glob.getStorage(s)) glob.setStorage(s,v);
		glob.prop.status[s] = v;
		glob.trigger(s, v);
	};
	glob.dropStatus = (s, broadcast) => {
		if (typeof s === "string") s = [s];
		if (!Array.isArray(s)) return;
		s.forEach(x => {
			delete glob.prop.status[x];
			glob.setStorage(x,undefined);
			if (broadcast) glob.trigger(x);
			delete glob.prop.registered[x];
		})
	};

	glob.getPref = (key) => {
		const p = glob.getStatus('Prefs') || {};
		return p[key];
	};
	glob.setPref = ( key, value ) => {
		const p = glob.getStatus('Prefs') || {};
		p[key] = value;
		glob.setStatus('Prefs', p, true);
		return value;
	};

	glob.getUserLogin = () => {
		return glob.getStatus('userLogin') || "";
	};
	glob.setUserLogin = (u, g) => {
		if (u && typeof u === "string") {
			glob.setStatus('userLogin', u);
		}
	}

	// -----------------------------------------------------------------------------------------------------------------

	glob.incrementRemoteAccessCounter = () => {
		const c = glob.getStatus('remoteAccessCounter') ?? 0;
		glob.setStatus('remoteAccessCounter', c +1 );
	};
	glob.decrementRemoteAccessCounter = () => {
		const c = glob.getStatus('remoteAccessCounter') ?? 1;
		glob.setStatus('remoteAccessCounter', c -1 );
	};

	glob.api = (params={}, data={}) => {
		if ( typeof params === 'string' ) params = { operation: params };
		if ( ! params?.operation ) throw (new Error('Missing operation in params: '+JSON.stringify(params)));
		const p = { params: glob.clone(params), data: glob.clone(data) };
		if ( ! p.params.schema ) p.params.schema = 'cs';
		if ( ! p.params.entity ) p.params.entity = 'config';
		const au = glob.getStatus('Auth');
		if ( au ) {
			p.params.username = au?.username;
			p.params.password = au?.password;
		}
		if ( ! p.params.lang ) p.params.lang = glob.getLanguage();
		const opts = {
			method: 'POST',
			cache: 'no-cache',
			credentials: 'include',
			referrerPolicy: 'no-referrer',
			headers: {
				'pragma': 'no-cache',
				'cache-control': 'no-cache',
				"Content-Type": "application/json",
				'Accept': 'application/json',
				'x-app-id' : glob.prop.appID
			},
		};
		opts.body = JSON.stringify(p);
		const uri = glob.prop.uri.api + '-/-/-';
		return new Promise( (resolve, reject) => {
			glob.incrementRemoteAccessCounter();
			window.fetch( uri, opts ).then( (response) =>{
				return response.json();
			}).then( (data) => {
				const err = data?.root?.error || data?.error;
				if ( err ) return reject(err);
				resolve( data?.root ? data.root : data );
			}).catch( e => {
				console.error( uri, structuredClone(opts) );
				reject([e]);
			}).finally( ()=>{
				glob.decrementRemoteAccessCounter();
			});
		});
	};

	glob.fetch = ( filename = 'data.json' ) => {
		if ( filename.startsWith('meteo') ) return glob.fetchMeteo();
		if ( filename.startsWith('traffic') ) return glob.fetchTraffic();
		const opts = {
			method: 'GET',
			cache: 'no-cache',
			credentials: 'include',
			referrerPolicy: 'no-referrer',
			headers: {
				'pragma': 'no-cache',
				'cache-control': 'no-cache',
				'Accept': 'application/json',
			},
		};
		return new Promise( (resolve, reject) => {
			if ( typeof filename !== 'string' ) return reject( new Error('Missing JSON filename'));
			if ( ! filename.endsWith('.json') ) filename += '.json';
			if ( ! glob.prop.cache ) glob.prop.cache = {};
			if ( glob.prop.cache[filename] ) return resolve(glob.clone(glob.prop.cache[filename]));
			if ( glob.prop.cache[filename] === false ) {
				setTimeout( async ()=>{
					resolve( await glob.fetch(filename));
				}, 100);
				return;
			}
			glob.prop.cache[filename] = false;
			const uri = glob.prop.uri.data + filename;
			glob.incrementRemoteAccessCounter();
			window.fetch( uri, opts ).then( (response) =>{
				return response.json();
			}).then( (data) => {
				glob.prop.cache[filename] = data;
				resolve( glob.clone(data) );
			}).catch( e => {
				console.error( uri, structuredClone(opts) );
				reject([e]);
			}).finally( ()=>{
				glob.decrementRemoteAccessCounter();
			});
		});
	};

	glob.lastFetched = ( filename = 'data.json') => {
		if ( ! filename.endsWith('.json') ) filename += '.json';
		if ( ! glob.prop.cache ) glob.prop.cache = {};
		return glob.prop.cache[filename] ? glob.clone(glob.prop.cache[filename]) : undefined;
	}

	glob.fetchMeteo = () => {
		const opts = {
			method: 'GET',
			cache: 'no-cache',
			referrerPolicy: 'no-referrer',
			mode: 'cors',
			headers: { 'Accept': 'application/json' }
		};
		const uri = glob.prop.isLAN ?
			'https://api.open-meteo.com/v1/forecast?latitude=42.250702&longitude=12.889300&hourly=temperature_2m,apparent_temperature,precipitation_probability&timezone=auto' :
			'/data/meteo.json';
		if ( ! glob.prop.cache ) glob.prop.cache = {};
		return new Promise( (resolve, reject) => {
			if ( glob.prop.cache['meteo.json'] ) return resolve(glob.clone(glob.prop.cache['meteo.json']));
			glob.incrementRemoteAccessCounter();
			window.fetch( uri, opts ).then( (response) =>{
				return response.json();
			}).then( (data) => {
				const out = [];
				const qt = data.hourly.time.length;
				for ( let i = 0; i < qt; i++ ) {
					out.push({
						date: new Date(data.hourly.time[i]),
						temp: data.hourly.temperature_2m[i],
						apptemp: data.hourly.apparent_temperature[i],
						rainpc: data.hourly.precipitation_probability[i]
					});
				}
				const now = new Date();
				now.setHours(now.getHours() -1);
				resolve( glob.clone( glob.prop.cache['meteo.json'] = out.filter( k => ( k.date >= now )) ) );
			}).catch( e => {
				console.error( uri, structuredClone(opts) );
				reject([e]);
			}).finally( ()=>{
				glob.decrementRemoteAccessCounter();
			});
		});
	}

	glob.fetchTraffic = () => {
		const opts = {
			method: glob.prop.isDeploy ? 'GET' : 'POST',
			cache: 'no-cache',
			referrerPolicy: 'no-referrer',
			mode: 'cors',
			headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
			body: glob.prop.isDeploy ? undefined : JSON.stringify({
				"reportType": "traffic",
				"lat": 42.250702,
				"lon": 12.889300,
				"range": 10000,
				"language": "it",
				"includeDetails": true,
				"includeAudio": false
			})
		};
		const uri = glob.prop.isDeploy ? '/data/traffic.json' : 'https://roma.luceverde.it/api/tov-aroundme';
		return new Promise((resolve, reject) => {
			glob.incrementRemoteAccessCounter();
			window.fetch(uri, opts).then((response) => {
				return response.json();
			}).then((data) => {
				const out = { meteo: data?.data?.weatherData?.owRawData?.current, traffic: [] };
				if ( Array.isArray(data?.data?.events?.features) ) {
					data.data.events.features.filter( f => ( f?.properties?.description )).forEach( f => {
						const p = f.properties;
						const o = { body: p.description };
						if ( p.affectedLocation ) o.name = p.affectedLocation;
						if ( p.targetLocation ) o.desc = p.targetLocation;
						out.traffic.push(o);
					});
				}
				if ( out.meteo ) {
					if ( out.meteo.sunrise ) {
						const t = new Date();
						t.setTime( out.meteo.sunrise * 1000 );
						out.meteo.sunrise = t;
					}
					if ( out.meteo.sunset ) {
						const t = new Date();
						t.setTime( out.meteo.sunset * 1000 );
						out.meteo.sunset = t;
					}
				}
				if ( ! glob.prop.cache ) glob.prop.cache = {};
				resolve( glob.clone( glob.prop.cache['traffic.json'] = out ) );
			}).catch(e => {
				console.error(uri, structuredClone(opts));
				reject([e]);
			}).finally(() => {
				glob.decrementRemoteAccessCounter();
			});
		});
	}

	// ------- Language --------

	glob.getLanguage = () => glob.getPref('language');

	glob.getLanguageLong = ( lang ) => {
		if ( lang && lang.length === 5 ) return lang;
		if ( typeof lang === 'undefined' ) {
			const ll = glob.getPref('languageLong');
			if ( ll ) return ll;
			lang = glob.getLanguage();
		}
		let longlang;
		switch ( lang ) {
			case 'en': longlang = "en-US"; break;
			default : longlang = lang + '-' + lang.toUpperCase()
		}
		return longlang;
	}

	glob.getDefaultLanguage = () => ( glob.prop.labelsDB?.default );

	glob.setLanguage = ( lang, langLong ) => {
		if ( ! glob.prop.labelsDB ) return setTimeout( ()=>{ glob.setLanguage(lang)} );
		const curlanguage = glob.getPref('language');
		if ( typeof lang === "undefined" && curlanguage ) lang = curlanguage;
		if ( typeof lang === "undefined" ) {
			const navl = glob.getStorage("Prefs")?.language;
			if ( navl ) lang = navl;
		}
		if ( typeof lang === "undefined") {
			const ll = window.navigator.language;
			const navl = ll.substring(0,2).toLowerCase();
			if ( glob.prop.labelsDB[navl] ) {
				lang = navl;
				langLong = ll;
			}
		}
		if ( typeof lang === "undefined") {
			(window.navigator.languages||[]).forEach( cl => {
				if ( typeof lang === "string" ) return;
				const navl = cl.substring(0,2).toLowerCase();
				if ( glob.prop.labelsDB[navl] ) {
					lang = navl;
					langLong = cl;
				}
			});
		}
		if ( lang && ! glob.prop.labelsDB[lang] ) lang = undefined;
		if ( typeof lang === "undefined") {
			const ulangs = (window.navigator.languages||[])
				.filter(x => (x && typeof x === "string") )
				.map( x => x.substring(0,2).toLowerCase() );
			const applangs = Object.keys(glob.prop.labelsDB)
				.filter( x => (x.length === 2) );
			lang = ulangs.find( x => ( applangs.includes(x) ) )||glob.prop.labelsDB.default;
		}
		if ( lang !== curlanguage ) {
			if ( ! langLong || langLong.length !== 5 ) langLong = glob.getLanguageLong( lang  );
			window.document.documentElement.setAttribute('lang',langLong);
			const p = glob.getStorage('Prefs') || {};
			p.language = lang;
			p.languageLong = langLong;
			glob.setStatus('Prefs', p, true);
		}
		return lang;
	};
	glob.loadLabelsData = () => {
		return new Promise( (resolve, reject) => {
			if ( glob.prop.labelsDB ) return resolve(glob.prop.labelsDB);
			window.fetch(
				glob.prop.uri.web + `media/labels.json`,
				{
					method: 'GET',
					cache: 'no-cache',
					headers: {
						'pragma': 'no-cache',
						'cache-control': 'no-cache',
						'Accept': 'application/json',
					},
					referrerPolicy: 'no-referrer'
				}
			).then( response => {
				if ( ! response.ok ) throw response;
				return response.json();
			}).then( jdata => {
					glob.prop.labelsDB = jdata;
					resolve( jdata );
			}).catch( e => {
				reject( e );
			});
		});
	};

	glob.label = ( key, values, lang ) => {
		if (typeof key !== "string" ) return "";
		if ( typeof lang === "undefined" ) lang = glob.getLanguage();
		if ( typeof lang === "undefined" ) return "";
		if ( ! (glob.prop.labelsDB && glob.prop.labelsDB[lang]) ) return "";
		let txt = glob.prop.labelsDB[lang][key] ?? glob.prop.labelsDB[glob.prop.labelsDB.default][key];
		if ( typeof txt === "undefined" ) return key;
		if ( values && typeof values === "object" ) {
			Object.entries( values ).forEach( ([k,v])=> {
				const re = new RegExp('\\{\\{'+k+'(\\|[^}]*)?\\}\\}','g');
				txt = txt.replace( re, v );
			});
		}
		txt = txt.replace(/\{\{[^|}]+\|([^}]*)\}\}/g,"$1");
		return txt;
	};

	glob.icon = ( key ) => glob.label( key, undefined, 'icon' );

	// -------- Storage ------

	glob.getStorageRepo = () => {
		if ( glob.prop.applicationStorage ) return glob.prop.applicationStorage;
		if ( ! (window.localStorage || window.sessionStorage )) return glob.prop.applicationStorage = {};
		if ( typeof glob.prop.storageCanPersist === 'undefined') {
			const ls = window.localStorage.getItem(glob.prop.keyApplicationName) ?? undefined;
			if (typeof ls !== 'undefined' ) glob.prop.storageCanPersist = true;
		}
		if ( window.localStorage && glob.prop.storageCanPersist ) {
			const ls = window.localStorage.getItem(glob.prop.keyApplicationName) ?? undefined;
			if (typeof ls !== 'undefined') return glob.prop.applicationStorage = JSON.parse(ls);
			window.localStorage.setItem(glob.prop.keyApplicationName,'{}');
		} else if ( window.sessionStorage ) {
			const ls = window.sessionStorage.getItem(glob.prop.keyApplicationName) ?? undefined;
			if (typeof ls !== 'undefined') return glob.prop.applicationStorage = JSON.parse(ls);
			window.sessionStorage.setItem(glob.prop.keyApplicationName,'{}');
		}
		return glob.prop.applicationStorage = {};
	};

	glob.getStorage = ( item ) => {
		const s = glob.getStorageRepo();
		return s[item];
	};

	glob.setStoragePersistence = ( persist = false ) => {
		if ( ! (window.localStorage && window.sessionStorage )) return;
		const cs = JSON.stringify(glob.getStorageRepo());
		if ( persist ) {
			window.localStorage.setItem( glob.prop.keyApplicationName, cs );
			window.sessionStorage.removeItem(glob.prop.keyApplicationName);
		} else {
			window.sessionStorage.setItem( glob.prop.keyApplicationName, cs );
			window.localStorage.removeItem(glob.prop.keyApplicationName);
		}
		glob.prop.storageCanPersist = !! persist;
	};

	glob.setStorage = ( item, value ) => {
		if ( ! (window.localStorage || window.sessionStorage )) return;
		const s = glob.getStorageRepo();
		if ( typeof value === 'undefined') {
			delete s[item];
		} else {
			s[item] = value;
		}
		glob.prop.applicationStorage = s;
		if ( window.localStorage && glob.prop.storageCanPersist ) {
			window.localStorage.setItem(glob.prop.keyApplicationName, JSON.stringify(s));
		} else  if ( window.sessionStorage ) {
			window.sessionStorage.setItem(glob.prop.keyApplicationName, JSON.stringify(s));
		}
	}

	glob.zapStorage = () => {
		if ( ! (window.localStorage || window.sessionStorage )) return;
		if ( window.localStorage && glob.prop.storageCanPersist ) {
			window.localStorage.removeItem(glob.prop.keyApplicationName);
		} else  if ( window.sessionStorage ) {
			window.sessionStorage.removeItem(glob.prop.keyApplicationName);
		}
	}

	// -------- Navigation ------

	glob.getInitialPage = (force) => {
		let op = glob.prop.homePage;
		if (window.location.hash.startsWith("#!/")) {
			const parts = window.location.hash.split("/");
			parts.shift();
			if (parts[0]) {
				op = {'name': parts.shift(), 'params': {}};
				if (parts.length) parts.filter(i => i.includes("=")).forEach(kv => {
					const p = kv.split("=");
					if (p[0]) {
						const k = p.shift();
						const v = p.join("=");
						op.params[k] = v || "";
					}
				});
			}
		}
		return op;
	};

	glob.object2paramsHash = obj => {
		const parts = [];
		if ( obj && typeof obj === "object") Object.keys(obj)
			.filter(k => !!k)
			.filter(k => ! k.startsWith('_'))
			.sort()
			.forEach(k => {
				let v = obj[k];
				v ??= "";
				parts.push(`${k}=${v}`);
			});
		return parts.length ? '/'+parts.join("/") : '';
	};

	glob.setCurrentPage = (op = glob.prop.homePage, force) => {
		if (!op) return;
		if (typeof op === "string") op = {"name": op};
		const newhash = "#!/" + op.name + glob.object2paramsHash(op.params);
		if (newhash !== window.location.hash) {
			window.history.pushState(null, op.name, glob.prop.uri.localpath + newhash);
			glob.setStatus('mainOperation', op);
		} else if (force) {
			glob.setStatus('mainOperation', op);
		}
	};

	glob.gotoHomePage = () => {
		glob.setCurrentPage()
	};

	glob.setPageByLocation = (force) => {
		glob.setCurrentPage(glob.getInitialPage(), force);
	};

	setTimeout(glob.setPageByLocation, 0);

	window.addEventListener('popstate', () => {
		glob.setPageByLocation(true);
	});

	glob.getCurrentPage = () => {
		let mo = glob.getStatus('mainOperation');
		if (mo) return mo;
		return glob.getInitialPage();
	};

	glob.getCurrentPageParams = () => {
		const mo = glob.getCurrentPage();
		return (mo.params || {});
	};

	glob.getCurrentPageParam = (pname) => {
		const mp = glob.getCurrentPageParams();
		return mp[pname];
	};

	glob.setCurrentPageParams = (params) => {
		const mo = glob.getCurrentPage();
		Object.entries(params).forEach(([k,v])=>{
			if ( typeof v === 'undefined' ) delete params[k];
		})
		mo.params = params;
		glob.setCurrentPage(mo);
	};

	glob.setCurrentPageParam = (pname, pvalue) => {
		const params = glob.getCurrentPageParams();
		params[pname] = pvalue;
		glob.setCurrentPageParams(params);
	};

	glob.rmCurrentPageParam = (pname) => {
		const params = glob.getCurrentPageParams();
		delete params[pname];
		glob.setCurrentPageParams(params);
	};

	glob.closeAllDialogs = () => {
		document.querySelectorAll('body > .dialogCurtain, body > .dialogBox').forEach(n => {
			n.remove()
		});
	};

	glob.isMobile = () => ('ontouchstart' in window.document.documentElement);

	if (true) {
		const urlSearchParams = new URLSearchParams(window.location.search);
		const pageParams = Object.fromEntries(urlSearchParams.entries());
		if (pageParams.debug) glob.prop.data.debug = true;
	}

	window.addEventListener( 'resize', () => {
		if ( glob.prop.timeoutWindowResizing ) window.clearTimeout(glob.prop.timeoutWindowResizing);
		glob.prop.timeoutWindowResizing = window.setTimeout( ()=>{
			delete glob.prop.timeoutWindowResizing;
			glob.trigger('windowResized', { width: parseInt(window.innerWidth), height: parseInt(window.innerHeight)});
		}, 100);
	});

	// -----------------------------------------------------------------------------------------------------------------

	glob.logout = ( force ) => {
		if (!force && !window.confirm(glob.label('ConfirmLogout'))) return;
		Object.keys( glob.prop.status )
			.filter( k => ( k!== 'Prefs'))
			.forEach( k => {
				glob.dropStatus(k);
			});
		window.location.reload();
	};

	// glob.logout = (force) => {
	// 	if (!force && !window.confirm("Si è certi di voler effettuare il logout?")) return;
	// 	const uri =
	// 		window.location.protocol + "//" +
	// 		window.location.hostname +
	// 		(parseInt(window.location.port) > 443 ? ":" + window.location.port : "") +
	// 		glob.prop.uri.localpath;
	// 	let xmlHttp = new XMLHttpRequest();
	// 	xmlHttp.mozBackgroundRequest = true;
	// 	xmlHttp.open("GET", uri, true, 'logout', 'logout');
	// 	xmlHttp.send(null);
	// 	setTimeout(
	// 		() => {
	// 			document.body.innerHTML = `
	// 			<div class="text-center mt-2">
	// 				<h2>Disconnessione effettuata</h2>
	// 				<p><a href="${uri}">Accedi di nuovo</a></p></p>
	// 			</div>
	// 		`;
	// 		},
	// 		1000
	// 	);
	// };

	if (window.navigator.standalone || window.matchMedia('(display-mode: standalone)').matches) {
		document.body.classList.add('standalone');
		glob.prop.data.standalone = true;
	}

	window.glob.loadLabelsData().then( ()=>{
		window.glob.setLanguage();
		Object.entries( (glob.getStorageRepo()||{}) ).forEach( ([k,v]) => {
			glob.setStatus(k,v);
		});
		glob.prop.loadedComponents.core = true;
	});

	window.debug = (x, level = 0) => {
		x = glob.clone(x);
		switch (level) {
			case 1: level = 'log'; break;
			case 2: level = 'warn'; break;
			case 3: level = 'error'; break;
			default: level = 'debug';
		}
		setTimeout( ()=>{
			switch (level) {
				case 'error': console.error(x); break;
				case 'warn': console.warn(x); break;
				case 'log': console.log(x); break;
				default: console.debug(x);
			}
		});
	}

	// Code to handle install prompt on desktop
	glob.isRunningInApp = () => {
		if ( typeof glob.prop.isRunningInApp === "boolean" ) return glob.prop.isRunningInApp;
		if (document.referrer.startsWith('android-app://')) {
			return true;
		}
		if (window.navigator.standalone || window.matchMedia('(display-mode: standalone)').matches) {
			return true;
		}
		if (window.navigator.fullscreen || window.matchMedia('(display-mode: fullscreen)').matches) {
			return true;
		}
		return false;
	}

	glob.prop.isRunningInApp = glob.isRunningInApp();

	if ( ! glob.prop.isRunningInApp ) {
		window.addEventListener('beforeinstallprompt', (e) => {
			e.preventDefault();
			if ( window.sessionStorage.getItem('AthsRefused') ) return;
			window.deferredAthsPrompt = e;
			glob.trigger('deferredAthsPrompt',window.deferredAthsPrompt);
		});
	}

}

/* Author: Marco Balestra <balestra@altersoftware.it> - Y-2023 */
