import React from 'react';
import "./Phonebook.css";
import Waiter from "../controls/Waiter.mjs";
import AutoIcon from "../controls/AutoIcon.mjs";

const G = window.glob;

const Phonebook = class Home extends React.Component {
	constructor(props) {
		super(props);
		this.state={};
		this.uid = G.generateUid('Phonebook');
		this.load = this.load.bind(this);
	}
	async load() {
		this.setState({list: await G.fetch('phonebook') });
	}
	render() {
		if ( ! this.state.list ) {
			setTimeout( this.load );
			return(
				<div className={"text-center"}>
					<Waiter />
				</div>
			);
		}
		return(
			<div className="Phonebook" id={this.uid}>
				<h1>Rubrica Castiglioni bassa</h1>
				<table>
					<tbody>
						{ this.state.list.map((e, i) => (
							<tr key={i} className={ e?.type ?? '' }>
								<td>
									<AutoIcon value={e?.type} />
								</td>
								<td>
									{e?.name}
								</td>
								<td>
									{e?.ph}
								</td>
							</tr>
						))}
					</tbody>
				</table>
				<h2>
					Numero fisso: <code><b>0765 1711365</b></code>
				</h2>
			</div>
		)
	}
}

export default Phonebook;
