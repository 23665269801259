import React from 'react';
import {Spinner} from "reactstrap";

const G = window.glob;

const Waiter = class extends React.Component {
	render() {
		return (
			<div className={"text-center"}>
				<Spinner color={"secondary"}>
					{ G.label('Loading') }
				</Spinner>
			</div>
		)
	}
}

export default Waiter;
