import React from 'react';

const LinkIcon = class extends React.Component {
	render() {
		return (
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="2 2 30 30" fill="currentColor" stroke="none" style={this.props.style||{}}>
				<path d="M28,13.5v5A4.5,4.5,0,0,1,23.5,23h-7A4.5,4.5,0,0,1,12,18.5V15a1,1,0,0,1,1-1h1a1,1,0,0,1,1,1v3.5A1.5,1.5,0,0,0,16.5,20h7A1.5,1.5,0,0,0,25,18.5v-5A1.5,1.5,0,0,0,23.5,12H21.86035a.49994.49994,0,0,1-.4743-.34186l-.66669-2A.5.5,0,0,1,21.19373,9H23.5A4.5,4.5,0,0,1,28,13.5ZM10.614,20.34186A.49994.49994,0,0,0,10.13965,20H8.5A1.5,1.5,0,0,1,7,18.5v-5A1.5,1.5,0,0,1,8.5,12h7A1.5,1.5,0,0,1,17,13.5V17a1,1,0,0,0,1,1h1a1,1,0,0,0,1-1V13.5A4.5,4.5,0,0,0,15.5,9h-7A4.5,4.5,0,0,0,4,13.5v5A4.5,4.5,0,0,0,8.5,23h2.30627a.5.5,0,0,0,.47437-.65814Z"/>
			</svg>
		);
	}
}

export default LinkIcon;