import React from 'react';
import "./Annunci.css";
import {UncontrolledCarousel} from "reactstrap";

const G = window.glob;

const Annunci = class extends React.Component {
	constructor(props) {
		super(props);
		const s = {};
		s.data = props.data ?? G.lastFetched('weekly');
		s.weekday = '*';
		this.state = s;
		this.uid = G.generateUid('Annunci');
		this.load = this.load.bind(this);
	}
	async load() {
		this.setState({ data: await G.fetch('weekly') });
	}
	render() {
		if ( typeof this.state.data === 'undefined' ) {
			setTimeout( this.load );
			return '';
		}
		const l = (this.state.data||[]).filter( i => (! i.disabled && (i.weekday === this.state.weekday)));
		if ( l.length === 0 ) return '';
		return(
			<div className={"WeekDay Annuncio"} id={this.uid}>
				{ this.props.carousel ? (
					<UncontrolledCarousel items={ l.map(( k, i ) => ({ caption: k.msg, altText: '', id: i })) } />
				) : (
					l.map(( l, i ) => <div key={ i }>{ l.msg }</div> )
				) }
			</div>
		);
	}
}

export default Annunci;
