import React from 'react';
import Waiter from "../controls/Waiter.mjs";
import TextLabel from "../controls/TextLabel.mjs";
import Linkset from "./Linkset.mjs";

const G = window.glob;

const Links = class Links extends React.Component {
	constructor(props) {
		super(props);
		this.uid = G.generateUid('Links');
		this.state = {
			lan: G.prop.isLAN,
			wan: ! G.prop.isLAN
		};
		this.load = this.load.bind(this);
	}
	async load() {
		const all = await G.fetch('links');
		const filtered = {};
		const labels = {};
		Object.entries(all).forEach( ([k,v]) => {
			if ( v.lan && this.state.wan ) return;
			if ( v.wan && this.state.lan ) return;
			v.links = (v.links||[]).filter( l => {
				if (l.lan && this.state.wan) return false;
				if (l.wan && this.state.lan) return false;
				return true;
			});
			if ( ! v.links.length ) return;
			filtered[k] = v;
			labels[k] = v.label ?? 'link-'+k;
		});
		this.setState({ data: filtered, keys: Object.keys(filtered), labels: labels });
	}
	render() {
		if ( typeof this.state.data === 'undefined' ) {
			setTimeout( this.load );
			return(
				<div className={"text-center"}>
					<Waiter />
				</div>
			);
		}
		return(
			<div className={"Links"} id={this.uid}>
				{ this.state.keys.map( ( k, i ) => (
					<div key={ i }>
						<h3>
							<TextLabel value={this.state.labels[k]} />:
						</h3>
						<Linkset data={this.state.data[k]} set={k} />
					</div>
				))}
			</div>
		)
	}
}

export default Links;