import React from 'react';
import Waiter from "../controls/Waiter.mjs";
import "./AddressBook.css";
import AutoIcon from "../controls/AutoIcon.mjs";

const G = window.glob;

const AddressBookEntry = class extends React.Component {
	render() {
		const d = this.props.data;
		if ( d === 'hr' ) return <hr />;
		if ( typeof d === 'string' ) return <h2>{ d }</h2>;
		// eslint-disable-next-line
		const mapuri = typeof d.map === 'string' ?
			d.map :
			'https://maps.google.it/?q='+encodeURIComponent((d.address||'') + ' '+ 'Oliveto Sabino');
		return (
			<div className={"AddressBookEntry"}>
				{ d.name && (
					<h3>
						{ d.link ? (
							<>
								<AutoIcon value={'link'}/>
								<a href={d.link} target="_blank" rel="noopener noreferrer">
									{d.name}
								</a>
							</>
						) : d.name
						}
					</h3>
				)}
				{d.desc && <div className={"desc"}>{d.desc}</div>}
				{d.address && d.map && (
					<div className={"address"}>
						<AutoIcon value={'share'}/>
						<a
							href={ mapuri }
							target="_blank"
							rel="noopener noreferrer"
						>
							{ d.address }
						</a>
					</div>
				)}
				{ d.address && (!d.map) && (
					<div className={"address"}>
						{ d.address }
					</div>
				)}
				{ d.note && (
					<div className={"note"}>
						<AutoIcon value={"calendar"} />
						{ d.note }
					</div>
				)}
				{ d.ph && (
					<div className={"ph"}>
						<AutoIcon value={"phone"} />
						<a href={"tel:"+d.ph.replace(/[^0-9]/g,'') }>{ d.ph }</a>
					</div>
				)}
			</div>
		);
	}
}

const AddressBook = class extends React.Component {
	constructor(props) {
		super(props);
		this.uid = G.generateUid('AddressBook');
		this.state = {};
		this.load = this.load.bind(this);
	}
	async load() {
		try {
			this.setState({list: await G.fetch('directory') });
		} catch (e) {
			this.setState({list: false });
		}
	}
	render() {
		if ( typeof this.state.list === 'undefined') {
			setTimeout( this.load );
			return(
				<div className={"text-center"}>
					<Waiter />
				</div>
			);
		}
		if ( ! this.state.list ) {
			return(
				<div className={"text-center"}>
					<h2>
						<span>Errore nel caricamento - </span>
						<a href={window.location.href} onClick={this.load}>Riprova</a>
					</h2>
				</div>
			);
		}
		if ( this.state.list.length === 0 ) return (<h2>Lista vuota</h2>);
		return(
			<div className={"AddressBook"} id={this.uid}>
				{ this.state.list.map((d, i) => <AddressBookEntry key={i} data={d} /> )}
			</div>
		);
	}
}

export default AddressBook;
