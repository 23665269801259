import React from "react";

const G = window.glob;

const TextLabel = class extends React.Component {
	constructor(props) {
		super(props);
		this.state = { lang: this.props.lang ?? G.getLanguage() };
		this.uid = G.generateUid("TextLabel");
	}
	componentDidMount() {
		if ( ! this.props.lang ) {
			G.register("Prefs", l=>{
				if ( l?.language) this.setState({ lang: l?.language });
			}, this.uid);
		}
	}
	componentWillUnmount() {
		if ( ! this.props.lang ) {
			G.unregister("Prefs", this.uid);
		}
	}
	render() {
		return G.label(this.props.value, this.props.params, this.state.lang);
	}
}

export default TextLabel;
