import React from 'react';
import TvIcon from "../media/assets/TvIcon.mjs"
import FoodIcon from "../media/assets/FoodIcon.mjs";
import GroupIcon from "../media/assets/GroupIcon.mjs";
import LinkIcon from "../media/assets/LinkIcon.mjs";
import TrashIcon from "../media/assets/TrashIcon.mjs";
import CalendarIcon from "../media/assets/CalendarIcon.mjs";
import OliveIcon from "../media/assets/OliveIcon.mjs";
import MeteoIcon from "../media/assets/MeteoIcon.mjs";
import PhoneIcon from "../media/assets/PhoneIcon.mjs";
import HomeIcon from "../media/assets/HomeIcon.mjs";
import MobileIcon from "../media/assets/MobileIcon.mjs";
import MailIcon from "../media/assets/MailIcon.mjs";
import CarIcon from "../media/assets/CarIcon.mjs";
import ShareIcon from "../media/assets/ShareIcon.mjs";
import AddressBookIcon from "../media/assets/AddressBookIcon.mjs";

const AutoIcon = class extends React.Component {
	render() {
		const v = this.props.value;
		if ( typeof v !== 'string' || ! v ) return '';
		switch (v) {
			case 'calendar' : return <CalendarIcon {...this.props} />;
			case 'directory' : return <AddressBookIcon {...this.props} />;
			case 'food' : return <FoodIcon {...this.props} />;
			case 'home': return <OliveIcon {...this.props} />;
			case 'house': return <HomeIcon {...this.props} />;
			case 'link' : return <LinkIcon {...this.props} />;
			case 'mail' : return <MailIcon {...this.props} />;
			case 'meteo': return <MeteoIcon {...this.props} />;
			case 'mobile': return <MobileIcon {...this.props} />;
			case 'phone': return <PhoneIcon {...this.props} />;
			case 'share' : return <ShareIcon {...this.props}/>;
			case 'social' : return <GroupIcon {...this.props} />;
			case 'traffic' : return <CarIcon {...this.props} />;
			case 'trash' : return <TrashIcon {...this.props} />;
			case 'tv' : return <TvIcon {...this.props} />;
			default: return <div className={"avatar"}>{v.toUpperCase().substring(0, 2)}</div>;
		}
	}
}

export default AutoIcon;
