import React from 'react';

const HomeIcon = class extends React.Component {
	render() {
		return (
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="2 2 30 30" fill="currentColor" stroke="none" style={this.props.style||{}}>
				<path d="M26.858,17H24V27.5a.5.5,0,0,1-.5.5h-4a.5.5,0,0,1-.5-.5v-5a.5.5,0,0,0-.5-.5h-5a.5.5,0,0,0-.5.5v5a.5.5,0,0,1-.5.5h-4a.5.5,0,0,1-.5-.5V17H5.142a.5.5,0,0,1-.36737-.83911L15.63257,4.398a.50006.50006,0,0,1,.73486,0l10.858,11.76294A.5.5,0,0,1,26.858,17Z"/>
			</svg>
		);
	}
}

export default HomeIcon;