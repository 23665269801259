import React from 'react';
import Waiter from "../controls/Waiter.mjs";
import WeekDay from "./WeekDay.mjs";

const G = window.glob;

const WholeWeek = class extends React.Component {
	constructor(props) {
		super(props);
		const l = [];
		const dt = new Date();
		dt.setDate( dt.getDate() - dt.getDay() );
		const lang = G.getLanguage();
		const langLong = G.getLanguageLong();
		for (let i = 1; i < 8; i++) {
			dt.setDate( dt.getDate() + 1);
			const wdn = String(i % 7);
			const lab = String(dt.toLocaleDateString(langLong, { weekday: 'long' }))
			l.push({
				weekday: wdn,
				label: lab.charAt(0).toUpperCase() + lab.slice(1),
			})
		}
		this.state = {
			list: l,
			data: G.lastFetched('weekly'),
			lang: lang,
			langLong: langLong
		};
		this.uid = G.generateUid('WholeWeek');
		this.load = this.load.bind(this);
	}
	componentDidMount() {
		G.register("Prefs", l=>{
			if ( l?.language !== this.state.lang ) this.setState({
				lang: l?.language,
				langLong: G.getLanguageLong( l?.language )
			});
		}, this.uid);
	}
	componentWillUnmount() {
		G.unregister("Prefs", this.uid);
	}
	async load() {
		this.setState({ data: await G.fetch('weekly') });
	}
	render() {
		if ( typeof this.state.data === 'undefined' ) {
			setTimeout( this.load );
			return(
				<div className={"text-center"}>
					<Waiter />
				</div>
			);
		}
		return(
			<div className={"WholeWeek mb-4"} id={this.uid}>
				{ this.state.list.map((d, i) => (
					<div className={"mb-2"} key={i}>
						<h4 className={"mt-0 mb-0"}>{ d.label }</h4>
						<WeekDay weekday={ d.weekday } data={ this.state.data } />
					</div>
				)) }
			</div>
		)
	}
}

export default WholeWeek;