import React from 'react';

const PhoneIcon = class extends React.Component {
	render() {
		return (
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="2 2 30 30" fill="currentColor" stroke="none" style={this.props.style||{}}>
				<path d="M28,10.7005V14a.82131.82131,0,0,1-.98682.83582L22.986,14.164A1.22473,1.22473,0,0,1,22,13V11.8158a1.98828,1.98828,0,0,0-1.74091-1.98346,38.14351,38.14351,0,0,0-8.51818,0A1.98828,1.98828,0,0,0,10,11.8158v1.18383a1.22514,1.22514,0,0,1-.98633,1.16443l-4.02685.67176A.82131.82131,0,0,1,4,14V10.7005A3.003,3.003,0,0,1,5.79028,7.9527C7.55286,7.16986,10.75616,6.23438,16,6.23438s8.44714.93548,10.20972,1.71832A3.003,3.003,0,0,1,28,10.7005Zm-8,3.66766V13a1,1,0,0,0-1-1H13a1,1,0,0,0-1,1v1.36816l-5.4,6.832A2.99922,2.99922,0,0,0,6,23v3a1,1,0,0,0,1,1H25a1,1,0,0,0,1-1V23a2.99922,2.99922,0,0,0-.6-1.7998Z"/>
			</svg>
		);
	}
}

export default PhoneIcon;