import React from 'react';
import "./AddToHomeScreen.css";
import {Button, ButtonGroup} from "reactstrap";
import TextLabel from "./TextLabel.mjs";

const disposeDialog = (evt) => {
	evt.target.closest('.AddToHomeScreen').classList.remove("active");
	delete window.deferredAthsPrompt;
	window.sessionStorage.setItem('AthsRefused','1');
};

const init = (uid) => {
	const tgt = window.document.getElementById(uid);
	tgt.classList.add('active');
	tgt.querySelector('button.add').addEventListener('click', (e) => {
		tgt.classList.remove("active");
		try { window.deferredAthsPrompt.prompt(); } catch (e) { }
		window.deferredAthsPrompt.userChoice.then((choiceResult) => {
				if (choiceResult.outcome === 'accepted') {
					window.localStorage.setItem('AthsAccepted','1');
					console.log('User accepted the A2HS prompt');
				} else {
					window.sessionStorage.setItem('AthsRefused','1');
					console.log('User dismissed the A2HS prompt');
				}
				delete window.deferredAthsPrompt;
			});
	});
};

class AddToHomeScreen extends React.Component {
	constructor(props) {
		super(props);
		this.uid = window.glob.generateUid("AddToHomeScreen");
	}
	componentDidMount() {
		if ( window.deferredAthsPrompt ) {
			init( this.uid);
		} else {
			window.glob.trigger('deferredAthsPrompt', () => { init( this.uid) }, this.uid);
		}
	}
	componentWillUnmount() {
		window.glob.unregister('deferredAthsPrompt', this.uid);
	}
	render() {
		return (
			<div className={"AddToHomeScreen"} id={this.uid}>
				<div className={"titlebar"}>
					<span className={"title"}>
						<TextLabel value={"Aggiungi alla Home"} />
					</span>
					<span className={"icon"}>ⓘ️</span>
					<span className={"closer"} onClick={disposeDialog}>✕</span>
				</div>
				<p>
					<TextLabel value={"AddToHomeBody"} />
				</p>
				<p>
					<TextLabel value={"AddToHomeBody2"}/>
				</p>
				<ButtonGroup>
					<Button className={"rm"} onClick={disposeDialog} color={"warning"}>
						✕ <TextLabel value={"Annulla"} />
					</Button>
					<Button className={"add"} color={"primary"}>
						✚ <TextLabel value={"Aggiungi alla Home"} />
					</Button>
				</ButtonGroup>
			</div>
		);
	}
}

export default AddToHomeScreen;