import React from 'react';
import "./Calendar.css";
import Waiter from "../controls/Waiter.mjs";
import {UncontrolledTooltip} from "reactstrap";
import AutoIcon from "../controls/AutoIcon.mjs";
import TextLabel from "../controls/TextLabel.mjs";

const G = window.glob;

const CalendarRow= class extends React.Component {
	constructor(props) {
		super(props);
		const s = {
			cm: (new Date() ).getMonth(),
			dopts: {weekday: 'short', day: 'numeric'},
			lang: G.getLanguage(),
			langLong: G.getLanguageLong()
		}
		const d = this.props.data;
		if ( d?.date?.getMonth() !== s.cm ) {
			s.dopts.month = 'short';
			const cy = (new Date() ).getFullYear();
			if ( d?.date.getFullYear() !== cy ) s.dopts.year = 'numeric';
		}
		s.ttip = !! d.long;
		this.state = s;
		this.uid = G.generateUid('CalendarRow');
	}
	componentDidMount() {
		G.register("Prefs", l=>{
			if ( l?.language !== this.state.lang ) this.setState({
				lang: l?.language,
				langLong: G.getLanguageLong( l?.language )
			});
		}, this.uid);
	}
	componentWillUnmount() {
		G.unregister("Prefs", this.uid);
	}
	render() {
		const d = this.props.data;
		const prevdate = this.props.prev ? this.props.prev.date.toLocaleString(this.state.langLong, this.state.dopts) : '';
		const thisdate = d.date.toLocaleString(this.state.langLong, this.state.dopts);
		return (
			<tr>
				<td className={"date"}>
					{ prevdate !== thisdate && thisdate + ',' }
				</td>
				<td className={"time"}>
					{d.date.toLocaleTimeString(this.state.langLong, {hour: '2-digit', minute: '2-digit'})}
				</td>
				<td className={"icon"}>
					<AutoIcon value={ d.cat } />
				</td>
				{
					this.state.ttip ? (
						<>
							<td className={"desc withinfo"} id={this.uid}>
								<TextLabel value={d.msg} />
							</td>
							<UncontrolledTooltip
								target={this.uid}
								placement={"auto"}
							>
								{d.long}
							</UncontrolledTooltip>
						</>
					) : (
						<td className={"desc"}>
							<TextLabel value={d.msg} />
						</td>
					)
				}
			</tr>
		);
	}
}

const Calendar = class extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.uid = G.generateUid('Calendar');
		this.load = this.load.bind(this);
	}

	async load() {
		const events = (await G.fetch('data')).map((d) => {
			const o = {}
			o.cat = d.cat;
			o.msg = d.msg;
			if (d.link) o.link = d.link;
			if (d.long) o.long = d.long;
			o.date = new Date(d.date);
			return o;
		}).filter( d =>
			( d && ! d.disabled )
		).sort((a, b) =>
			( a.date - b.date )
		);
		this.setState({events: events});
	}

	render() {
		if (typeof this.state.events === 'undefined') {
			setTimeout(this.load);
			return (
				<div align={"center"} id={this.uid}>
					<Waiter />
				</div>
			);
		}
		const ne = this.state.events.filter( e => (e.date >= (new Date())) ).filter( (e,i)=>( ! this.props.max || this.props.max > i ));
		if ( ne.length === 0 ) {
			return (
				<h3>
					<TextLabel value={"NoWeeklyEvents"}/>
				</h3>
			);
		}
		return (
			<div className={"Calendar"} id={this.uid}>
				<table>
					<tbody>
						{ ne.map((d, i) => <CalendarRow key={i} data={d} prev={ i === 0 ? undefined : ne[i-1]} /> ) }
					</tbody>
				</table>
			</div>
		);
	}
}

export default Calendar;