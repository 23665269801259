import React from "react";
import VSwitch from "./VSwitch.mjs";
import TextLabel from "./TextLabel.mjs";
import enFlag from "../media/assets/en.svg"
import itFlag from "../media/assets/it.svg";
import "./LanguageSwitcher.css";

const G = window.glob;

const LanguageSwitcher = class extends React.Component {
	constructor(props) {
		super(props);
		this.state = { language: G.getLanguage() }
		this.state.items = [
			{ value:'en', label: <div><img src={enFlag} alt={""}/>English</div> },
			{ value:'it', label: <div><img src={itFlag} alt={""}/>Italiano</div> }
		];
		this.uid = G.generateUid("LanguageSwitcher");
	}
	componentDidMount() {
		if ( ! this.props.lang ) {
			G.register("Prefs", l=>{
				if ( l?.language) this.setState({ language: l?.language });
			}, this.uid);
		}
	}
	componentWillUnmount() {
		if ( ! this.props.lang ) {
			G.unregister("Prefs", this.uid);
		}
	}
		render() {
		if ( ! this.state.language ) return "";
		return(
			<div
				className={"LanguageSwitcher"}
				id={this.uid}
				role={"form"}
				aria-label={ <TextLabel value={"Language"} /> }
			>
				<VSwitch
					title={ <TextLabel value={"Language"} /> }
					current={ this.state.language }
					items={ this.state.items }
					onChange={ (item)=>{ G.setLanguage( item.value ) }}
				/>
			</div>
		);
	}
}

export default LanguageSwitcher;