import React from 'react';

const OliveIcon = class extends React.Component {
	render() {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 32 32"
				fill={"none"}
				stroke={"none"}
				style={this.props.style || {}}
			>
				<path
					fill="#44911b"
					d="m25.2672 6.72412c5.8 5.79998 6.35 14.63998 1.23 19.75998s-13.96 4.57-19.76003-1.23c-5.799998-5.8-6.349997-14.64-1.23-19.74998 5.12003-5.110004 13.96003-4.570004 19.76003 1.22z"
				/>
				<path
					fill="#86d72f"
					d="m15.5272 10.0541c-2.44-.90998-3.96-2.90998-3.37-4.47998.58-1.56 3.03-2.09 5.48-1.19s3.96 2.91 3.37 4.48c-.59 1.56998-3.03 2.09998-5.48 1.18998z"
				/>
				<path
					fill="#f8312f"
					d="m9.99714 14.4241c2.44666 0 4.42996-1.9834 4.42996-4.42998 0-2.44662-1.9833-4.43-4.42996-4.43-2.44662 0-4.43 1.98338-4.43 4.43 0 2.44658 1.98338 4.42998 4.43 4.42998z"
				/>
			</svg>
		);
	}
}

export default OliveIcon;