import React from 'react';
import Waiter from "../controls/Waiter.mjs";
import TextLabel from "../controls/TextLabel.mjs";

const G = window.glob;

const WeekDay = class extends React.Component {
	constructor(props) {
		super(props);
		const s = {};
		s.data = props.data ?? G.lastFetched('weekly');
		s.weekday = String(props.weekday ?? (new Date()).getDay());
		this.state = s;
		this.uid = G.generateUid('WeekDay');
		this.load = this.load.bind(this);
	}
	async load() {
		this.setState({ data: await G.fetch('weekly') });
	}
	render() {
		if ( typeof this.state.data === 'undefined' ) {
			setTimeout( this.load );
			return(
				<div className={"text-center"}>
					<Waiter />
				</div>
			);
		}
		const l = (this.state.data||[]).filter( i => (! i.disabled && (i.weekday === this.state.weekday)));
		return(
			<div className={"WeekDay"} id={this.uid}>
				{ l.length ? l.map(( l, i ) => (
					<div key={ i }>
						{ l.msg }
					</div>
				)) : (
					<span>
						<TextLabel value={"NoNotices"} />
					</span>
				)}
			</div>
		);
	}
}

export default WeekDay;
