import React from 'react';
import "./Meteo.css";
import Waiter from "../controls/Waiter.mjs";
import AutoIcon from "../controls/AutoIcon.mjs";
import TextLabel from "../controls/TextLabel.mjs";

const G = window.glob;

const Meteo = class extends React.Component {
	constructor(props) {
		super(props);
		const qtdays = props.qtdays ? parseInt(props.qtdays) : 1;
		const minDate = new Date();
		minDate.setHours(minDate.getHours() );
		const maxDate = new Date();
		maxDate.setDate( maxDate.getDate() + qtdays );
		this.state = {
			qtdays: qtdays,
			data: props.data ?? G.lastFetched('meteo'),
			minDate : minDate,
			maxDate : maxDate,
			lang: G.getLanguage(),
			langLong: G.getLanguageLong()
		}
		if ( props.title) {
			if ( typeof props.title === 'string' ) {
				this.state.title = props.title;
			} else {
				this.state.title = 'Weather forecasts';
			}
		}
		this.uid = G.generateUid('Meteo');
		this.load = this.load.bind(this);
	}
	componentDidMount() {
		G.register("Prefs", l=>{
			if ( l?.language !== this.state.lang ) this.setState({
				lang: l?.language,
				langLong: G.getLanguageLong( l?.language )
			});
		}, this.uid);
	}
	componentWillUnmount() {
		G.unregister("Prefs", this.uid);
	}
	async load() {
		try {
			this.setState({data: await G.fetchMeteo()});
		} catch (e) {
			this.setState({data: false});
		}
	}
	render() {
		if ( typeof this.state.data === 'undefined' ) {
			setTimeout( this.load );
			return(
				<div className={"text-center"}>
					<Waiter />
				</div>
			);
		}
		const dts = (this.state.data || []).filter( x => ( x.date >= this.state.minDate && x.date <= this.state.maxDate ) );
		if ( dts.length === 0 ) return '';
		let lud = dts[0].date.getDate();
		let mint,maxt,minp,maxp;
		if ( this.state.qtdays === 1) {
			mint = maxt = dts[0].temp;
			minp = maxp = dts[0].apptemp;
			dts.forEach( x => {
				if ( x.temp > maxt ) maxt = x.temp;
				else if ( x.temp < mint ) mint = x.temp;
				if ( x.apptemp > maxp ) maxp = x.apptemp;
				else if ( x.apptemp < minp ) minp = x.apptemp;
			});
		}
		return(
			<div className={"Meteo"} id={this.uid}>
				{ this.state.title && (
					<h2>
						<AutoIcon value={"meteo"} />
						<TextLabel value={this.state.title} />
					</h2>
				)}
				<table>
					<thead>
					<tr>
						<th>
							<TextLabel value={"Day"} />
						</th>
						<th>
							<TextLabel value={"Hours"} />
						</th>
						<th>
							<TextLabel value={"Temperature"} />
						</th>
						<th>
							<TextLabel value={"Perceived"} />
						</th>
						<th>
							<TextLabel value={"Rain"} />
						</th>
					</tr>
					</thead>
					<tbody>
						{dts.map((l, i) => {
							const td = l.date.getDate();
							const dc = td !== lud;
							lud = td;
							return (
								<tr key={i} className={ dc ? 'datechanged' : ''}>
									<td className={"giorno"}>
										{(dc || i === 0) ? l.date.toLocaleString(this.state.langLong, {weekday: 'long', day: 'numeric'}) : ''}
									</td>
									<td className={"ore"}>
										{l.date.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'}).replace(/:00$/,'')}
									</td>
									<td className={"temp"+( l.temp === mint ? " min" : (l.temp === maxt ? ' max' : '')) }>
										{l.temp}
									</td>
									<td className={"temp apptemp"+( l.apptemp === minp ? " min" : (l.apptemp === maxp ? ' max' : ''))}>
										{l.apptemp}
									</td>
									<td className={"rain"}>
										{l.rainpc}
									</td>
								</tr>
							);
						})}
						<tr>
							<td colSpan={5} className={"pt-3 text-center"}>
								<small>
									[<a href="https://open-meteo.com/" target={"_blank"} rel="noreferrer">Weather data by Open-Meteo.com</a>]
								</small>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		)
	}
}

export default Meteo;