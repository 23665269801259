import React from 'react';
import AutoIcon from "../controls/AutoIcon.mjs";

const MobileHowTo = class extends React.Component {
	render() {
		const uri = window.location.href.replace(/#!.*/,'');
		return (
			<div
				className={"MobileHowTo"}
				style={{
					maxWidth: 'min(96%, 640px)',
					margin: '0 auto'
				}}
			>
				<h3>
					Come mettere sul proprio device la App di Ollivud
				</h3>
				<div className={"text-start"}>
					<p>
						È possibile installare <b>{ window.location.hostname }</b> come se fosse una app sul proprio
						telefono o tablet, oppure sul browser Chrome in uso.
					</p>
				</div>
				<div className={"text-start"}>
					<h4 className={"mt-3"}>
						iPhone e iPad
					</h4>
					<div>
						<ul>
							<li>Aprire la pagina <a href={uri}>{uri}</a> su Safari sull’iPhone o iPad.</li>
							<li>
								Fare tap (click con il dito) sul <b>pulsante di condivisione</b>
								<AutoIcon value={"share"} style={{ height: "1.4rem"}} /> posto nella barra degli strumenti
							</li>
							<li>
								Selezionare l’opzione <b>Aggiungi alla schermata Home</b>.<br />
								<span style={{opacity:'.6'}}>
									Se questa azione non fosse disponibile inizialmente è possibile aggiungerla
									andando sul pulsante <b>Modifica azioni</b> che si trova nella parte inferiore
									della scheda di condivisione.
								</span>
							</li>
							<li>
								A questo punto non resta che modificare il nome del collegamento da inserire sulla
								schermata iniziale e fare tap su <b>Aggiungi</b>.
							</li>
						</ul>
						<p>
							Fatto questo il collegamento al sito sarà aggiunto sulla Home e l’utente potrà gestirlo
							come se si trattasse di una qualsiasi applicazione.
						</p>
					</div>
				</div>
				<h4 className={"mt-3"}>
					Android e Chrome
				</h4>
				<div className={"text-start"}>
					<ul>
						<li>Aprire la pagina <a href={uri}>{uri}</a> su Chrome o su qualunque browser su Android.</li>
						<li>Toccare <b>l'icona del menu coi tre punti</b> (tre punti in verticale) in alto a destra</li>
						<li>
							Selezionare l’opzione <b>Aggiungi alla schermata principale</b><br />
							<i>(su Chrome desktop: <b>Installa Home Oliveto Sabino…</b>)</i>.
						</li>
						<li>
							Come per iPhone è possibile <b>modificare il nome del collegamento</b> e inserirlo sulla
							Home semplicemente facendo tap su <b>Aggiungi</b>.
						</li>
					</ul>
					<p>
						A questo punto il sito apparirà proprio come fosse una qualsiasi applicazione che l’utente
						può trascinare dove vuole sullo smartphone o aggiungerlo in una cartella.
					</p>
				</div>
			</div>
		);
	}
}

export default MobileHowTo;