import React from 'react';
import '../media/css/App.css';
import {Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import Home from "./Home.mjs";
import Phonebook from "./Phonebook.mjs";
import Links from "./Links.mjs";
import Calendar from "./Calendar.mjs";
import WholeWeek from "./WholeWeek.mjs";
import Meteo from "./Meteo.mjs";
import AddToHomeScreen from "../controls/AddToHomeScreen.mjs";
import AutoIcon from "../controls/AutoIcon.mjs";
import MobileHowTo from "./MobileHowTo.mjs";
import AddressBook from "./AddressBook.mjs";
import TextLabel from "../controls/TextLabel.mjs";
import Annunci from "./Annunci.mjs";

const G = window.glob;

const App = class extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			tab: G.getCurrentPage()?.name,
			lan: G.prop.isLAN,
			wan: ! G.prop.isLAN,
			app: G.prop.isRunningInApp
		}
		this.uid= G.generateUid('App');
	}
	componentDidMount() {
		const isMobile = (parseInt(window.innerWidth) < 640);
		document.body.classList.toggle('mobile', isMobile);
		document.body.classList.toggle('desktop', !isMobile);
		G.register('windowResized', (w) => {
			const isMobile = (w.width < 640);
			document.body.classList.toggle('mobile', isMobile);
			document.body.classList.toggle('desktop', !isMobile);
		}, this.uid)
		G.register( 'mainOperation', mo => {
			if ( mo?.name ) this.setState({ tab: mo.name });
		}, this.uid);
	}
	componentWillUnmount() {
		G.deregister( this.uid );
	}
	render() {
		return (
			<>
				<div
					className={"App"}
					id={this.uid}
				>
					<Nav tabs>
						<NavItem>
							<NavLink
								active={ this.state.tab === 'Home' }
								onClick={ ()=>{ G.setCurrentPage('Home') } }
							>
								<AutoIcon value={"home"} />
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								active={ this.state.tab === 'Calendar' }
								onClick={ ()=>{ G.setCurrentPage('Calendar') } }
							>
								<AutoIcon value={"calendar"} />
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								active={ this.state.tab === 'Meteo' }
								onClick={ ()=>{ G.setCurrentPage('Meteo') } }
							>
								<AutoIcon value={"meteo"} />
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								active={ this.state.tab === 'AddressBook' }
								onClick={ ()=>{ G.setCurrentPage('AddressBook') } }
							>
								<AutoIcon value={"directory"} />
							</NavLink>
						</NavItem>
						{ this.state.lan && (
							<NavItem>
								<NavLink
									active={ this.state.tab === 'Phonebook' }
									onClick={ ()=>{ G.setCurrentPage('Phonebook') } }
								>
									<AutoIcon value={"phone"} />
								</NavLink>
							</NavItem>
						)}
						<NavItem>
							<NavLink
								active={ this.state.tab === 'Links' }
								onClick={ ()=>{ G.setCurrentPage('Links') } }
							>
								<AutoIcon value={"link"} />
							</NavLink>
						</NavItem>
						{ ! this.state.app && (
							<NavItem>
								<NavLink
									active={ this.state.tab === 'MobileHowTo' }
									onClick={ ()=>{ G.setCurrentPage('MobileHowTo') } }
								>
									<AutoIcon value={"mobile"} />
								</NavLink>
							</NavItem>
						)}
					</Nav>
					<TabContent activeTab={ this.state.tab }>
						<TabPane tabId={'Home'}>
							{ this.state.tab === 'Home' && <Home /> }
						</TabPane>
						<TabPane tabId={'Calendar'}>
							{ this.state.tab === 'Calendar' && (
								<>
									<Annunci />
									<WholeWeek />
									<h3>
										<TextLabel value={"Dates"} />
									</h3>
									<Calendar max={60}/>
								</>
							)}
						</TabPane>
						<TabPane tabId={'Meteo'}>
							{ this.state.tab === 'Meteo' && <Meteo qtdays={7} title={true} /> }
						</TabPane>
						<TabPane tabId={'AddressBook'}>
							{ this.state.tab === 'AddressBook' && <AddressBook /> }
						</TabPane>
						<TabPane tabId={'Phonebook'}>
							{ this.state.tab === 'Phonebook' && <Phonebook /> }
						</TabPane>
						<TabPane tabId={'Links'}>
							{ this.state.tab === 'Links' && <Links /> }
						</TabPane>
						<TabPane tabId={'MobileHowTo'}>
							{ this.state.tab === 'MobileHowTo' && <MobileHowTo /> }
						</TabPane>
					</TabContent>
				</div>
				<AddToHomeScreen />
			</>
		)
	}
}


export default App;
