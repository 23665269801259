import React from 'react';
import "./Traffic.css";
import AutoIcon from "../controls/AutoIcon.mjs";
import TextLabel from "../controls/TextLabel.mjs";

const G = window.glob;

const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

const Traffic = class extends React.Component {
	constructor(props) {
		super(props);
		this.state = { data:  G.lastFetched('traffic'), loaded: false };
		this.uid = G.generateUid('Traffic');
		this.load = this.load.bind(this);
	}
	async load() {
		try {
			this.setState({ data: await G.fetchTraffic(), loaded: true });
		} catch (e) {
			this.setState({ data: false, loaded: true });
		}
	}
	render() {
		if ( ! this.state.loaded ) setTimeout( this.load );
		if ( !this.state.data || (! this.state.data.meteo && this.state.data.traffic.length === 0) ) return '';
		const w = this.state.data.meteo;
		const t = this.state.data.traffic;
		const n = new Date();
		return(
			<div className={"Traffic"} id={this.uid}>
				{ w && (
					<div className={"d-flex align-items-center flex-column gap-1"}>
						<h3>
							<TextLabel value={"TemperatureAt"} params={{value:this.state.data.meteo.temp}} />
						</h3>
						<h3>
							{ w.weather && w.weather[0]?.description && capitalize(w.weather[0].description) }
							{ w.humidity ? ', '+G.label('humidity_percent',{value:+w.humidity}) : '' }
						</h3>
						{ (w.sunrise && w.sunrise > n) && (
							<h3>
								<TextLabel value={"SunriseAt"} params={{value:w.sunrise.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'})}} />
							</h3>
						) }
						{ (w.sunset && w.sunset > n) && (
							<h3>
								<TextLabel value={"SunsetAt"} params={{value:w.sunset.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'})}} />
							</h3>
						) }
					</div>
				)}
				{ t.length > 0 ? (
					<>
						<h2 className={ w ? 'mt-3' : ''}>
							<AutoIcon value={"traffic"} />
							<TextLabel value={"Traffic advisory"} />:
						</h2>
						{ t.map( (d,i)=>(
							<div key={ i }>
								{ d.name && <h3>{d.name}</h3> }
								{ d.desc && <h4>{d.desc}</h4> }
								<p>{ d.body }</p>
							</div>
						))}
					</>
				) : (
					<div className={"text-center mt-3"}>
						<TextLabel value={"TrafficNoIssues"} />
					</div>
				)}
			</div>
		)
	}
}
export default Traffic;